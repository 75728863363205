import { Validator } from './index'
import { SEVERITY, ValidationError } from './ValidationError'
import { trimSlashes } from '../trimSlashes'
import { Redirect } from '../../store/thunks/generate'

export const loopValidator: Validator = (input) => {
  const errors: ValidationError[] = []
  const inputMap = new Map<string, Redirect>(input.map(input => [trimSlashes(input.source), input]))
  const loopingRedirects = new Set<Redirect>()

  for (const [i, redirect] of Object.entries(input)) {
    if (loopingRedirects.has(redirect)) {
      // We've already identified this redirect is part of an infinite loop chain, skip
      continue
    }

    if (trimSlashes(redirect.source) === trimSlashes(redirect.destination)) {
      errors.push(new ValidationError(redirect, 'Redirect input and output are the same for a single redirect (infinite loop)', SEVERITY.critical, i))
      continue
    }

    let chainLength = 1
    const findLastRedirect = (chain: Redirect[]): Redirect[] => {
      if (chainLength++ > 10) {
        return chain // Stop checking after 10
      }

      const last = chain[chain.length - 1]

      if (inputMap.has(trimSlashes(last.destination))) {
        const next = inputMap.get(trimSlashes(last.destination)) as Redirect

        if (chain.find((c) => c === next)) {
          return [...chain, next]
        } else {
          return findLastRedirect([...chain, next])
        }
      }

      return chain
    }

    const chain = findLastRedirect([redirect])
    if (chain.length > 1) {
      if (chain[0] === chain[chain.length - 1]) {
        for (const redirect of chain) {
          loopingRedirects.add(redirect)
        }

        errors.push(
          new ValidationError(chain.slice(0, chain.length - 1), `Infinite loop detected between ${chain.length - 1} redirects (redirect loop)`, SEVERITY.critical, i)
        )
      } else {
        errors.push(
          new ValidationError(chain, `Redirect chain of ${chain.length} redirects detected (unnecessary redirects)`, SEVERITY.note, i)
        )
      }
    }
  }

  return errors
}