import { Redirect } from '../../store/thunks/generate'
import { ValidationError } from './ValidationError'
import { loopValidator } from './loopValidator'
import { duplicateValidator } from './duplicateValidator'
import { originValidator } from './originValidator'

export type Validator = (input: Redirect[]) => ValidationError[]

const validators: Validator[] = [
  loopValidator,
  duplicateValidator,
  originValidator,
]

export const validate = (input: Redirect[]): ValidationError[] => {
  const errors: ValidationError[] = []

  for (const validator of validators) {
    errors.push(...validator(input))
  }

  return errors
}