import React, { useCallback, useMemo, useState } from 'react'
import { Dropzone } from './components/dropzone/dropzone'
import {
  Alert,
  AlertColor,
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Container,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { HeaderSelector } from './components/headerSelector/headerSelector'
import { useAppDispatch, useAppSelector } from './store/hooks'
import { generate } from './store/thunks/generate'
import styled from 'styled-components'
import { copy } from './store/thunks/copy'
import { download } from './store/thunks/download'
import { SEVERITY, ValidationError } from './lib/validators/ValidationError'
import { OutputSelector } from './components/outputSelector/outputSelector'
import { outputs } from './lib/outputs'

const OutputContainer = styled.pre`
  max-height: 300px;
  overflow: auto;
`

function App() {
  const dispatch = useAppDispatch()
  const onClick = useCallback(() => dispatch(generate()), [dispatch])
  const outputData = useAppSelector(state => state.generator.output)
  const outputType = useAppSelector(state => state.generator.outputType)
  const output = useMemo(() => outputData ? outputs[outputType](outputData) : undefined, [outputData, outputType])
  const errors = useAppSelector(state => state.generator.output?.errors)
  const onCopyClick = useCallback(() => dispatch(copy()), [dispatch])
  const onDownloadClick = useCallback(() => dispatch(download()), [dispatch])
  const errorCounts = useAppSelector(state => {
    const counts: Record<SEVERITY, number> = {
      [SEVERITY.note]: 0,
      [SEVERITY.warning]: 0,
      [SEVERITY.critical]: 0,
    }

    if (state.generator.output?.errors) {
      for (const error of state.generator.output.errors) {
        counts[error.severity]++
      }
    }

    return counts
  })

  return (
    <div>
      <Dropzone>
        <Container maxWidth={'lg'}>
          <Grid container gap={3} direction={'column'}>
            <Grid item width={'100%'}>
              <Paper elevation={1}>
                <Box p={3}>
                  <Grid container gap={2} direction={'column'}>
                    <Grid item xs={12}>
                      <HeaderSelector />
                    </Grid>
                    <Grid item xs={12}>
                      <OutputSelector />
                    </Grid>
                    <Grid item xs={12}>
                      <Button onClick={onClick} variant={'contained'}>
                        Generate
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            {output && (
              <>
                <Grid item width={'100%'}>
                  <Paper elevation={1}>
                    <Box p={3}>
                      {errors && errors.length > 0 ? (
                        <>
                          <Typography variant={'body1'}>
                            {errorCounts[SEVERITY.critical]} critical issues, {errorCounts[SEVERITY.warning]} warnings, and {errorCounts[SEVERITY.note]} notes found.
                          </Typography>
                          {errors.map((error) => (
                            <ValidationAlert error={error} />
                          ))}
                        </>
                      ) : (
                         <Alert severity={'success'}>
                           No validation errors found!
                         </Alert>
                       )}
                    </Box>
                  </Paper>
                </Grid>
                <Grid item width={'100%'}>
                  <Paper elevation={1}>
                    <Box p={3}>
                      <OutputContainer>
                        {output}
                      </OutputContainer>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item width={'100%'}>
                  <Box justifyContent={'flex-end'} display={'flex'}>
                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                      <Button onClick={onCopyClick}>Copy</Button>
                      <Button onClick={onDownloadClick} variant="contained">Download</Button>
                    </ButtonGroup>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </Dropzone>
    </div>
  );
}

export const ValidationAlert: React.VFC<{ error: ValidationError }> = ({ error }) => {
  const [open, setOpen] = useState(false)
  const severity: AlertColor = useMemo(() => {
    switch (error.severity) {
      case SEVERITY.note:
        return 'info'
      case SEVERITY.warning:
        return 'warning'
      case SEVERITY.critical:
        return 'error'
    }
  }, [error])

  return (
    <Box mt={2}>
      <Alert severity={severity} action={(
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            setOpen(!open);
          }}
        >
          {open ? (
            <KeyboardArrowUpIcon fontSize="inherit" />
          ) : (
             <KeyboardArrowDownIcon fontSize="inherit" />
           )}
        </IconButton>
      )}>
        #{error.line} - {error.message}

        <Collapse in={open}>
          <StyledCollapseInner>
            {Array.isArray(error.redirect) ? (
              <ol>
                {error.redirect.map((redirect) => (
                  <li>
                    <pre>{redirect.source} {'->'} {redirect.destination}</pre>
                  </li>
                ))}
              </ol>
            ) : (
               <pre>{error.redirect.source} {'->'} {error.redirect.destination}</pre>
             )}
          </StyledCollapseInner>
        </Collapse>
      </Alert>
    </Box>
  )
}

const StyledCollapseInner = styled.div`
  width: 100%;
  overflow: auto;
`

export default App;
