import { Redirect } from '../../store/thunks/generate'

export enum SEVERITY {
  note,
  warning,
  critical,
}

export class ValidationError extends Error {
  constructor(public readonly redirect: Redirect | Redirect[], message: string, public readonly severity: SEVERITY = SEVERITY.warning, public readonly line: number | string) {
    super(message);
  }
}