import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { formatOutput } from '../../lib/formatOutput'

export const download = createAsyncThunk('generator/download', (_, thunkAPI) => {
  const output = (thunkAPI.getState() as RootState).generator.output
  const outputType = (thunkAPI.getState() as RootState).generator.outputType
  const typeMap = {
    json: 'application/json',
    apache: 'text/plain',
    nginx: 'text/plain',
  } as const

  if (output) {
    const a = document.createElement('a')
    const type = typeMap[outputType]
    const blob = new Blob([formatOutput(output, outputType)], { type })
    const url = URL.createObjectURL(blob)
    const date = new Date()

    a.download = `redirects-${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}.json`
    a.href = url
    a.click()
  }
})
