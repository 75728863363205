import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { generate, GeneratedOutput } from '../thunks/generate'
import { OutputType } from '../../lib/outputs'
import { load } from './data'

type GeneratorType = {
  headers: {
    source: string
    destination: string
  },
  outputType: OutputType
  output?: GeneratedOutput
  outputOptions: Record<string, unknown> & {
    permanent: boolean
  }
}

export const initialState: GeneratorType = {
  headers: {
    source: '',
    destination: '',
  },
  outputType: 'apache',
  outputOptions: {
    permanent: false,
  },
}

const { actions, reducer } = createSlice({
  name: 'generator',
  initialState,
  reducers: {
    setHeader: (state, action: PayloadAction<{ header: 'source' | 'destination', value: string }>) => {
      state.headers[action.payload.header] = action.payload.value
      state.output = undefined
    },
    setOutputType: (state, action: PayloadAction<OutputType>) => {
      state.outputType = action.payload
      state.output = undefined
    },
    setOutputOption: (state, action: PayloadAction<{ option: string, value: unknown }>) => {
      state.outputOptions[action.payload.option] = action.payload.value
      state.output = undefined
    },
  },
  extraReducers: builder => {
    builder.addCase(generate.fulfilled, (state, action) => {
      state.output = action.payload
    }).addCase(load, (state, action) => {
      const headers = action.payload[0]

      if (headers)  {
        // If there are 2 headers, assume the first is the source and the second is the destination
        if (headers.length === 2) {
          state.headers = {
            source: headers[0],
            destination: headers[1],
          }
        } else {
          // Otherwise, try to find the source and destination headers
          const source = headers.find(header => header.toLowerCase() === 'source' || header.toLowerCase() === 'from')
          const destination = headers.find(header => header.toLowerCase() === 'destination' || header.toLowerCase() === 'to')

          if (source) {
            state.headers.source = source
          }

          if (destination) {
            state.headers.destination = destination
          }
        }
      }
    })
  }
})

export const generator = reducer

export const { setHeader, setOutputType, setOutputOption } = actions