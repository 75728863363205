import { Validator } from './index'
import { SEVERITY, ValidationError } from './ValidationError'
import { trimSlashes } from '../trimSlashes'
import { Redirect } from '../../store/thunks/generate'

export const duplicateValidator: Validator = (input) => {
  const errors: ValidationError[] = []
  const inputMap = new Map<string, Redirect>(input.map(input => [trimSlashes(input.source), input]))

  for (const [i, redirect] of Object.entries(input)) {
    const duplicate = inputMap.get(trimSlashes(redirect.source))
    if (duplicate && duplicate !== redirect) {

      errors.push(
        new ValidationError([duplicate, redirect], 'Duplicate redirect source found (duplicate redirects)', SEVERITY.warning, i)
      )
    }
  }

  return errors
}