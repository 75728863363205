import React, { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { setHeader } from '../../store/slices/generator'

const inputs = {
  'source': 'Source',
  'destination': 'Destination',
} as const

type InputType = keyof typeof inputs

export const HeaderSelector: React.VFC = () => {
  const headers = useAppSelector(state => state.data.headers)
  const { source, destination } = useAppSelector(state => state.generator.headers)
  const dispatch = useAppDispatch()
  const onChange = useCallback((input: InputType) => (event: SelectChangeEvent) => {
    dispatch(setHeader({
      header: input,
      value: event.target.value,
    }))
  }, [dispatch])

  return (
    <Grid container gap={2}>
      {Object.keys(inputs).map((key) => (
        <Grid item xs key={key}>
          <FormControl fullWidth>
            <InputLabel id={`header-selector-${key}`}>
              {inputs[key as InputType]}
            </InputLabel>
            <Select label={inputs[key as InputType]} value={key === 'source' ? source : destination} onChange={onChange(key as InputType)} labelId={`header-selector-${key}`}>
              <MenuItem disabled value={''}>
                Please select
              </MenuItem>
              {headers.map((header, i) => (
                <MenuItem key={i} value={header}>
                  {header}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      ))}
    </Grid>
  )
}