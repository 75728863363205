import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { ValidationError } from '../../lib/validators/ValidationError'
import { validate } from '../../lib/validators'

export type Redirect = {
  source: string
  destination: string
  permanent: boolean
}

export type GeneratedOutput = {
  output: Redirect[]
  errors: ValidationError[]
}

export const generate = createAsyncThunk('generator/generate', (_, thunkAPI) => {
  const state = thunkAPI.getState() as RootState
  const { permanent } = state.generator.outputOptions
  const sourceIndex = state.data.headers.findIndex(header => header === state.generator.headers.source)
  const destinationIndex = state.data.headers.findIndex(header => header === state.generator.headers.destination)
  const output = []

  if (sourceIndex === -1 || destinationIndex === -1) {
    throw new Error('Failed to find indexes')
  }

  for (const line of state.data.data) {
    const source = line[sourceIndex]
    const destination = line[destinationIndex]

    output.push({ source, destination, permanent })
  }

  return {
    output,
    errors: validate(output),
  }
})