import React, { useCallback } from 'react'
import { css } from '@mui/material'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import Papa from 'papaparse'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { load } from '../../store/slices/data'

export type DropzoneProps = {
  //
}

const Wrapper = styled('div')<{ $disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.05);
  overflow: auto;
  
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  
  ${props => props.$disabled && css`
    cursor: inherit;
    background: rgba(0, 0, 0, 0.05) !important;
  `}
`

const Heading = styled('h1')`
  font-size: 42px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
`

const Inner = styled('div')`
`

export const Dropzone: React.FC<DropzoneProps> = ({ children }) => {
  const dispatch = useAppDispatch()
  const onDrop = useCallback((files: File[]) => {
    const file = files[0]

    if (file) {
      Papa.parse<string[]>(file, {
        complete(results) {
          dispatch(load(results.data))
        }
      })
    }
  }, [dispatch])

  const hasLoaded = useAppSelector(state => state.data.loaded)
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'text/csv', maxFiles: 1, disabled: hasLoaded })

  return (
    <Wrapper { ...getRootProps() } $disabled={hasLoaded}>
      <input { ...getInputProps() } />
      <Heading>
        Drop CSV Here
      </Heading>
      {children && hasLoaded && (
        <Inner>
          {children}
        </Inner>
      )}
    </Wrapper>
  )
}