import { GeneratedOutput } from '../store/thunks/generate'

export const jsonOutput = (output: GeneratedOutput) => JSON.stringify(output, null, 2)

export const apacheOutput = (output: GeneratedOutput) => {
  const lines = ['RewriteEngine On', '']

  for (let { source, destination, permanent } of output.output) {
    const isFullUrl = source.startsWith('http://') || source.startsWith('https://')
    const code = permanent ? 301 : 302

    // For partial links, we need to remove the leading slash, and allow for an optional trailing slash. We also need to do any necessary escaping.
    if (!isFullUrl) {
      source = source.replace(/^\/|\/$/g, '')
      source = source.replace(/([.*+?^=!:${}()-])/g, '\\$1')
      source = `^${source}/?$`
    }

    lines.push(`RewriteRule ${source} ${destination} [R=${code},L]`)
  }

  return lines.join('\n')
}

export const nginxOutput = (output: GeneratedOutput) => {
  const lines = []

  for (let { source, destination, permanent } of output.output) {
    const isFullUrl = source.startsWith('http://') || source.startsWith('https://')

    // For partial links, we need to remove the leading slash, and allow for an optional trailing slash. We also need to do any necessary escaping.
    if (!isFullUrl) {
      source = source.replace(/^\/|\/$/g, '')
      source = source.replace(/([.*+?^=!:${}()-])/g, '\\$1')
      source = source ? `^/${source}/?$` : '^/$'
    }

    lines.push(`rewrite ${source} ${destination} ${permanent ? 'permanent' : 'redirect'};`)
  }

  return lines.join('\n')
}

export const outputs = {
  json: jsonOutput,
  apache: apacheOutput,
  nginx: nginxOutput,
} as const

export type OutputType = keyof typeof outputs