import { Validator } from './index'
import { SEVERITY, ValidationError } from './ValidationError'

export const originValidator: Validator = (input) => {
  const errors: ValidationError[] = []

  for (const [i, redirect] of Object.entries(input)) {
    if (redirect.source.match(/^(https?|.*\.\w+[./])/)) {
      errors.push(
        new ValidationError(redirect, 'Detected FQDN in redirect source', SEVERITY.note, i)
      )
    }
  }

  return errors
}